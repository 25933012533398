const actionTypes = {
  GET_APARTMENTS: 'GET_APARTMENTS',
  CREATE_APARTMENT: 'CREATE_APARTMENT',
  DELETE_APARTMENT: 'DELETE_APARTMENT',
  UPDATE_APARTMENT: 'UPDATE_APARTMENT',
  GET_RENTED_APARTMENTS: 'GET_RENTED_APARTMENTS',
  ADD_RENTED_APARTMENT: 'ADD_RENTED_APARTMENT',
  DELETE_RENTED_APARTMENT: 'DELETE_RENTED_APARTMENT',
}

export default actionTypes
